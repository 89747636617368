/**
 * Box-sizing
 */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
}

/**
 * Typography
 */
body,
html.wf-loading body {
  font-family: sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4;
}
html.wf-active.wf-notosanssc-n4-active body.f-notosanssc .f {
  font-family: 'Noto Sans SC', sans-serif;
  font-style: normal;
  font-weight: normal;
}
html.wf-active.wf-notoserifsc-n4-active body.f-notoserifsc .f {
  font-family: 'Noto Serif SC', serif;
  font-style: normal;
  font-weight: normal;
}
html.wf-active.wf-mashanzheng-n4-active body.f-mashanzheng .f {
  font-family: 'Ma Shan Zheng', cursive;
  font-style: normal;
  font-weight: normal;
}
p {
  margin-top: 0;
}
a,
a:hover,
a:active {
  color: inherit;
}
h1,
h2,
h3 {
  margin-bottom: 0;
  font-family: sans-serif;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.6em;
}

/**
 * Layout
 */
.layout__container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.layout__header,
.layout__body {
  position: absolute;
  right: 0;
  left: 0;
}
.layout__header {
  z-index: 3;
  top: 0;
  height: 4em;
  background-image: linear-gradient(to bottom, white 99%, #666 99%, white 100%);
}
.layout__body {
  top: 4em;
  bottom: 0;
  padding: 0.7em;
  overflow-y: scroll;
}
